import { useEffect, useState } from "react";
import Header from "../components/Header";
import { Request } from "../helpers/https";
import { SimpleToastError, SimpleToastSuccess } from "../helpers/validation/error-handlers";
import { read, utils } from "xlsx";
import { getLocalStorage } from "../helpers/utils";

const BatchTransaction = () => {
    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState();
    const [sheetData, setSheetData] = useState([]);
    // const [mediaCampaigns, setMediaCampaigns] = useState([]);
    const [campaigns, setCampaigns] = useState([]);



    //=> {ext: 'png', mime: 'image/png'}


    const formatString = (str) => {
        return str.substring(0, 8) + '...' + str.substring(str.length - 6)
    }

    const displayExcelSheet = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        const excelTable = document.getElementById('excelTable');

        reader.onload = () => {
            const fileData = reader.result;
            const workbook = read(fileData, { type: 'binary' });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const sheetData = utils.sheet_to_json(sheet, { header: 1 });

            for (let i = 0; i < sheetData.length; i++) {
                if (typeof sheetData[i][1] !== 'string') {
                    sheetData.splice(i)
                }
            }

            // display the sheet data in a table
            let tableHtml = '';
            for (let row of sheetData) {
                tableHtml += '<tr>';
                for (let cell of row) {
                    tableHtml += '<td>' + cell + '</td>';
                }
                tableHtml += '</tr>';
            }
            excelTable.innerHTML = tableHtml;
            setSheetData(sheetData);
        };

        reader.readAsBinaryString(file);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const data = new FormData(e.target);
        data.append('data', JSON.stringify(sheetData));
        data.append('File', selectedFile);

        const values = Object.fromEntries(data.entries());

        let token = getLocalStorage('mw:user:session:id');
        let config = {
            headers: {
                "Authorization": "Bearer " + token,
            }
        }
        let url = `${process.env.REACT_APP_SERVER_URL}transactions/batchTransactions`;
        let result = await Request.put(url, values, config)
        // console.log({ result: result.data })
        if (result.ok) {
            SimpleToastSuccess(result.data.data.data)
            setInterval(() => {
                window.location.reload();
            }, 2000);
        } else {
            SimpleToastError(result.data.response.data.data)
        }
        setLoading(false)

    }

    const validateFileExtension = (fileName) => {
        try {
            let justFileName = fileName.split('.')
            let fileExtension = justFileName[1]
            if (fileExtension !== 'xlsx')
                throw new Error("Invalid file format!")

            return { ok: true }
        } catch (err) {
            SimpleToastError(err.message)
            return { ok: false }
        }
    }

    const changeHandler = (event) => {
        let res = validateFileExtension(event.target.files[0].name);
        if (res?.ok) {
            setSelectedFile(event.target.files[0]);
            displayExcelSheet(event)
        }
    };



    async function fetchCampaigns() {
        let url = `${process.env.REACT_APP_SERVER_URL}campaigns/1/10`;
        let result = await Request.get(url)
        if (result.ok) {
            setCampaigns(result.data.data.data.data)
        }
    }



        useEffect(async () => {
            await fetchCampaigns()
        }, []);



        return (

            <div className="App">
                <Header title={'batch transaction'} />
                <div className="row ml-auto mr-auto justify-content-center mb-lg-5">
                    <div className="col-md-9">
                        <table id="excelTable" className="table table-striped table-hover"></table>
                    </div>
                </div>
                <div className="row ml-auto mr-auto">
                    <form className='m-auto' onSubmit={handleSubmit}>
                        <div className="form-group d-flex mb-4">
                            <div className="col-md-2">
                                <label> Campaign:</label>
                            </div>
                            <div className="col-md-6">
                                <select className='form-select' name="campaignId" id="type">
                                    <option value="">-- Select campaign --</option>
                                    {campaigns?.map((e, index) => (
                                        <option key={index} value={e.id}>{e.name}</option>
                                    ))
                                    }
                                </select>

                            </div>
                        </div>
                        <div className="form-group d-flex mb-4">
                            <div className="col-md-2">
                                <label> Network:</label>
                            </div>
                            <div className="col-md-6">
                                <select className='form-select' name="network" id="type">
                                    <option value="">-- Select network --</option>
                                    <option value="mainnet">Mainnet</option>
                                    <option value="bsc">BSC</option>
                                    <option value="matic">Matic</option>
                                </select>

                            </div>
                        </div>
                        <div className="form-group d-flex mb-4">
                            <div className="col-md-2">
                                <label >Select Excel Sheet:</label>
                            </div>
                            <div className="col-md-6">
                                {/* <input type="text" className="form-control" required="true" name="txnHash" placeholder="Enter transaction hash..." /> */}
                                <input type="file" name="file" onChange={changeHandler} />

                            </div>
                        </div>



                        {
                            (loading) ?
                                <button type="submit" className="btn btn-primary ms-5">
                                    <div className="spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div> Processing...
                                </button>

                                :
                                <>

                                    <button type="submit" className="btn btn-primary">Upload</button>
                                </>
                        }


                    </form>
                </div>

            </div>
        );
    
}
export default BatchTransaction;