import { useNavigate } from "react-router-dom";
import { Request } from "../helpers/https";
import { unSetLocalStorage } from "../helpers/utils";

const Header = ({ title }) => {
    let navigate = useNavigate();

    const logout = async () => {
        unSetLocalStorage('mw:user:session:id')

        let url = `${process.env.REACT_APP_SERVER_URL}logout`;
        let result = await Request.get(url)
        if (result.ok) {
            navigate('/');
        }
    }

    return (

        <div className="container">
            <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
                <a className="d-flex align-items-center col-md-3 mb-2 mb-md-0 text-dark text-decoration-none">
                    <svg className="bi me-2" width="40" height="32" role="img" aria-label="Bootstrap"><use xlinkHref="#bootstrap" /></svg>
                </a>

                <ul className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                    {/* <li><a href="/" className="nav-link px-2 link-secondary">Campaigns</a></li>
                    <li><a href="/transactions" className="nav-link px-2 link-dark">Transactions</a></li> */}
                    <li><a href="/home" className={`nav-link px-2 link-secondary ${title == 'campaigns' ? 'link-dark' : null}`}>Transaction Campaign</a></li>
                    {/* <li><a href="/create-transaction" className={`nav-link px-2 link-secondary ${title == 'transactions' ? 'link-dark' : null}`}>Transactions</a></li> */}
                    <li><a href="/create-excel-campaign" className={`nav-link px-2 link-secondary ${title == 'Excel Campaign' ? 'link-dark' : null}`}>Media Campaign</a></li>
                    <li><a href="/batch-transaction" className={`nav-link px-2 link-secondary ${title == 'batch transaction' ? 'link-dark' : null}`}>Batch </a></li>
                    {/* <li><a href="#" className="nav-link px-2 link-dark">Pricing</a></li>
                    <li><a href="#" className="nav-link px-2 link-dark">FAQs</a></li>
                    <li><a href="#" className="nav-link px-2 link-dark">About</a></li> */}
                </ul>

                <div className="col-md-3 text-end">
                    <button type="button" onClick={logout} className="btn btn-outline-primary me-2">Logout</button>
                    {/* <button type="button" className="btn btn-primary">Sign-up</button> */}
                </div>
            </header >
        </div >
    );
}

export default Header;