import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Request } from "../helpers/https";
import { setLocalStorage } from "../helpers/utils";
import { SimpleToastError, SimpleToastSuccess } from "../helpers/validation/error-handlers";


/* 
-   The user sends a request to the server with their login credentials.
-   The server checks the credentials and verifies the user's identity.
-   Once the user's identity is verified, the server generates a token and sends it back to the user.
-   The user stores the token locally on their device.
-   For subsequent requests that require authentication, the user sends the token along with the request.
-   Upon receiving the request, the server checks the validity of the token to ensure that it was generated by the server and is not expired.
-   If the token is valid, the server grants access to the requested resource or service.

 */

const Login = () => {
    let navigate = useNavigate();
    const [loading, setLoading] = useState(false)


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const data = new FormData(e.target);
        const values = Object.fromEntries(data.entries());
        // console.log({ values }); return;

        let url = `${process.env.REACT_APP_SERVER_URL}login`;
        let result = await Request.post(url, values)
        if (result.ok) {
            // console.log({ result: result.data.data.data })
            let { token } = result.data.data.data;
            if (setLocalStorage('mw:user:session:id', token)) {
                // SimpleToastSuccess(result.data.data.data)
                navigate('/home');
                // window.location.reload();
            }
        } else {
            // console.log({ result: result.data.response.data })
            SimpleToastError(result.data.response.data.data)
        }
        setLoading(false)
    }


    return (
        <div className="app">

            <div className="row mt-5 ml-auto m-auto">
                <div className="col-md-12 ml-5 mr-5">
                    <form className='' onSubmit={handleSubmit}>
                        <div className="form-group d-flex mb-4">
                            <div className="col-md-2">
                                <label >Email:</label>
                            </div>
                            <div className="col-md-6">
                                <input type="email" className="form-control" required={true} name="email" placeholder="Enter email address..." />

                            </div>
                        </div>
                        <div className="form-group d-flex mb-4">
                            <div className="col-md-2">
                                <label >Password:</label>
                            </div>
                            <div className="col-md-6">
                                <input type="password" className="form-control" required={true} name="password" placeholder="Enter password..." />

                            </div>
                        </div>

                        {
                            (loading) ?
                                <button type="submit" className="btn btn-primary ms-5">
                                    <div className="spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div> Processing...
                                </button>

                                :
                                <>

                                    <button type="submit" className="btn btn-primary">Login</button>
                                </>
                        }


                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;