import { SimpleToastError, SimpleToastSuccess } from "../helpers/validation/error-handlers";
import { useEffect, useState } from "react";
import Header from "../components/Header";
import { Request } from "../helpers/https";
import dotenv from "dotenv"
import { convertDateToTimestamp } from "../script";
import { getLocalStorage } from "../helpers/utils";
import { useParams } from "react-router-dom";
dotenv.config()


const EditCampaign = () => {
    const { campaignId } = useParams();
    const [campaign, setCampaign] = useState({});
    const [campaigns, setCampaigns] = useState([]);

    const [mainnetSupportedTokens, setMainnetSupportedTokens] = useState([]);
    const [bscSupportedTokens, setBscSupportedTokens] = useState([]);
    const [maticSupportedTokens, setMaticSupportedTokens] = useState([]);
    const [loading, setLoading] = useState(false)
    let [tokensCount, setTokensCount] = useState(1);
    let [moreTokens, setMoreTokens] = useState(true)

    let [startDate, setStartDate] = useState('')
    let [endDate, setEndDate] = useState('')


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const data = new FormData(e.target);
        const values = Object.fromEntries(data.entries());
        values.token = await formatSelectedTokens();
        // console.log('YYY', values.token);return;

        values.startDate = await convertDateToTimestamp(values.startDate)
        values.endDate = await convertDateToTimestamp(values.endDate)


        let token = getLocalStorage('mw:user:session:id');
        let config = {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
        let url = `${process.env.REACT_APP_SERVER_URL}campaigns/update/${campaignId}`;
        let result = await Request.post(url, values, config)
        // console.log({ result }); return;
        if (result.ok) {
            SimpleToastSuccess(result.data.data.data)
            setInterval(() => {
                window.location.reload();
            }, 2000);
        } else {
            SimpleToastError(result.data.response.data.data)
        }
        setLoading(false)
    }

    // const fetchCampaigns = async () => {
    //     let url = `${process.env.REACT_APP_SERVER_URL}campaigns/1/10`;
    //     let result = await Request.get(url)
    //     if (result.ok) {
    //         setCampaigns(result.data.data.data.data)
    //     }
    // }
    const fetchSupportedTokens = async () => {
        let url, result;
        url = 'https://stake.xend.tools/networks/1/tokens';
        result = await Request.get(url)
        if (result.ok) {
            setMainnetSupportedTokens(result.data.data)
        }

        let bscUrl = 'https://stake.xend.tools/networks/56/tokens';
        let bscResult = await Request.get(bscUrl)
        if (bscResult.ok) {
            setBscSupportedTokens(bscResult.data.data)
        }

        let maticUrl = 'https://stake.xend.tools/networks/137/tokens';
        let maticResult = await Request.get(maticUrl)
        if (maticResult.ok) {
            setMaticSupportedTokens(maticResult.data.data)
        }
    }

    const createTokenSelectElement = async (e) => {
        // console.log(e.target.value);
        let networkSelected = e.target.value;
        let networkElement = e.target;
        let networkColElement = networkElement.parentNode
        let networkRowElement = networkColElement.parentNode
        let tokenColElement = networkRowElement.children[2]
        let tokenSelectElement = tokenColElement.children[0]
        // tokenSelectElement.removeChild()
        var first = tokenSelectElement.firstElementChild;
        while (first) {
            first.remove();
            first = tokenSelectElement.firstElementChild;
        }

        let networkTokens;
        switch (networkSelected) {
            case 'mainnet':
                networkTokens = mainnetSupportedTokens;
                break;
            case 'bsc':
                networkTokens = bscSupportedTokens;
                break;
            case 'matic':
                networkTokens = maticSupportedTokens;
                break;
            default:
                break;
        }

        let option = document.createElement('option');
        option.setAttribute('value', "selected");
        let title = document.createTextNode("-- Select Token --");
        option.appendChild(title);
        tokenSelectElement.append(option)

        networkTokens.map((e, index) => {
            let option = document.createElement('option');
            option.setAttribute('value', e.address);
            let title = document.createTextNode(e.name);
            option.appendChild(title);
            tokenSelectElement.append(option)
        })
    }

    const renderNewTokenRow = () => {
        let rows = [];
        for (let i = 0; i < tokensCount; i++) {
            rows.push(
                <div key={i} className="row d-flex justify-content-around pl-5 input-block mb-3">
                    <div className="col-md-1">
                        <label> Token:</label>
                    </div>
                    <div className="col-md-4">
                        <select className='form-select token-details' onInput={createTokenSelectElement} >
                            <option value="selected">-- Select Network --</option>
                            <option value="mainnet">Mainnet</option>
                            <option value="bsc">BSC</option>
                            <option value="matic">Matic</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select className='form-select token-details' >
                            <option value="selected">-- Select Token --</option>
                        </select>
                    </div>
                </div>
            );
        }
        return rows;
    }
    const formatSelectedTokens = async () => {
        let tokens = [];
        let inputs = document.getElementsByClassName("token-details")
        let count = 0;
        let tokenObject = {};

        for (let i = 0; i < inputs.length; i++) {
            switch (count) {
                case 0:
                    tokenObject.network = inputs[i].value
                    break;
                case 1:
                    tokenObject.token = inputs[i].value
                    break;
                default:
                    break;
            }
            if (count === 1) {
                tokens.push(tokenObject);
                count = 0;
                tokenObject = {}
            } else {
                count++
            }
        }
        return tokens;
    }

    const handleMoreTokens = (e) => {
        let campaignType = e.target.value
        if (campaignType == 'general') {
            setMoreTokens(false)
        } else {
            setMoreTokens(true)
        }
    }

    const setCampaignStatus = async (e) => {
        let campaignId = e.target.getAttribute("id")
        let token = getLocalStorage('mw:user:session:id');
        let config = {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
        let url = `${process.env.REACT_APP_SERVER_URL}campaigns/status/${campaignId}`;
        let result = await Request.post(url, {}, config)
        // console.log({ result }); return;
        if (result.ok) {
            SimpleToastSuccess('Campaign status updated!')
            setInterval(() => {
                window.location.reload();
            }, 2000);
        } else {
            SimpleToastError(result.data.response.data.data)
        }
    }

    const formatUnixDate = (unixTimestamp) => {
        // console.log(unixTimestamp)
        const date = new Date(unixTimestamp * 1000);
        const year = date.getFullYear();
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`; // format the date and time as desired

    }

    async function fetchCampaign() {
        let url = `${process.env.REACT_APP_SERVER_URL}campaigns/view/${campaignId}`;
        let result = await Request.get(url)
        // console.log({ 'xxx': result.data.data.data[0] })
        if (result.ok) {
            setCampaign(result.data.data.data[0])
        }
    }

    function handleChange(event) {
        setStartDate(event.target.value);
    }


    useEffect(async () => {
        await fetchCampaign()
        await fetchSupportedTokens()
    }, []);

    useEffect(() => {
        setStartDate(formatUnixDate(campaign.start_date))
        setEndDate(formatUnixDate(campaign.end_date))
    }, [campaign])


    return (
        <div className="App">

            <Header title={'campaigns'} />

            <div className="row ml-auto mr-auto">
                <form className='m-auto' onSubmit={handleSubmit}>
                    <div className="form-group d-flex mb-4">
                        <div className="col-md-2">
                            <label >Name:</label>
                        </div>
                        <div className="col-md-8">
                            <input type="text" className="form-control" required={true} name="name" placeholder="Enter name for campaign..." defaultValue={campaign.name} />

                        </div>
                    </div>
                    <div className="form-group d-flex mb-4">
                        <div className="col-md-2">
                            <label >Description:</label>
                        </div>
                        <div className="col-md-8">
                            <input type="text" className="form-control" required={true} name="desc" placeholder="Enter description..." defaultValue={campaign.desc} />

                        </div>
                    </div>
                    <div className="form-group d-flex mb-4">
                        <div className="col-md-2">
                            <label> Type:</label>
                        </div>
                        <div className="col-md-8">
                            <select value={campaign.type} disabled className='form-select' name="type" id="type" onInput={handleMoreTokens}>
                                <option value="swap">Swap</option>
                                <option value="bridge">Bridge</option>
                                <option value="general">General</option>
                            </select>

                        </div>
                    </div>
                    <div className="form-group d-flex mb-4 justify-content-around input-block">

                        <div className="col-md-1">
                            <label> Start Date:</label>
                        </div>
                        <div className="col-md-4">
                            <input type="date" className="form-control" onChange={(e) => setStartDate(e.target.value)} required={true} value={startDate} name="startDate" />

                        </div>
                        <div className="col-md-1">
                            <label> End Date:</label>
                        </div>
                        <div className="col-md-4">
                            <input type="date" className="form-control" onChange={(e) => setEndDate(e.target.value)} required={true} value={endDate} name="endDate" />
                        </div>
                    </div>
                    <div className="form-group d-flex mb-4 justify-content-around input-block">

                        <div className="col-md-1">
                            <label> BlockHeights:</label>
                        </div>
                        <div className="col-md-3">
                            <input type="number" className="form-control" defaultValue={campaign.mainnet_start_block_height} required={true} name="mainnetBlockHeight" placeholder="Mainnet Start Block..." />

                        </div>
                        <div className="col-md-3">
                            <input type="number" className="form-control" defaultValue={campaign.matic_start_block_height} required={true} name="maticBlockHeight" placeholder="Matic Start Block..." />

                        </div>
                        <div className="col-md-3">
                            <input type="number" className="form-control" defaultValue={campaign.bsc_start_block_height} required={true} name="bscBlockHeight" placeholder="Bsc Start Block..." />

                        </div>
                    </div>
                    <div className="form-group">
                        <hr></hr>
                    </div>
                    {
                        // moreTokens &&
                        // <div className="form-group mb-4">
                        //     {tokensCount && renderNewTokenRow()}
                        // </div>
                    }

                    {
                        (loading) ?
                            <button type="submit" className="btn btn-primary ms-5">
                                <div className="spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div> Processing...
                            </button>

                            :
                            <>
                                {/* {moreTokens && <a onClick={() => { setTokensCount(tokensCount++) }} className="btn btn-secondary me-5">
                                    Add more tokens
                                </a>} */}
                                <button type="submit" className="btn btn-primary ms-5">
                                    Update Campaign
                                </button>
                            </>
                    }
                </form>
            </div>


        </div>
    );
}

export default EditCampaign;