
import './App.css';
import React from "react";
import { Route, BrowserRouter as Router, Routes, Navigate, useNavigate } from "react-router-dom";
import Home from './pages/Home';
import Transactions from './pages/Transactions';
import { Toaster } from 'react-hot-toast';
import Login from './pages/Login';
import { getLocalStorage } from './helpers/utils';
import ExcelCampaign from './pages/ExcelCampaign';
// import CreateTransaction from './pages/CreateTransaction';
import BatchTransaction from './pages/BatchTransaction';
import EditCampaign from './pages/EditCampaign';
import CreateExcelCampaign from './pages/CreateExcelCampaign';
import AChainCampaign from './pages/AChainCampaign';
import ABugCampaign from './pages/ABugCampaign';


function PrivateRoute({ children, ...props }) {
  const navigate = useNavigate()
  let authStatus = isAuthenticated()
  return authStatus ? children : <Navigate to="/" />;
}

function isAuthenticated() {
  let token = getLocalStorage('mw:user:session:id')
  if (!token) {
    return false;
  } else {
    return true
  }
}

function App() {

  return (
    <Router>
      <Toaster position="top-right" />
      <Routes>

        <Route exact path="/" element={<AChainCampaign />} />
        <Route exact path="/bug-campaign" element={<ABugCampaign />} />
        {/* <Route exact path="/" element={<Login />} /> */}
        {/* <Route exact path="/home" element={<Home />} /> */}
        {/* <Route exact path="/transactions/:campaignId" element={<Transactions />} /> */}


        <Route path="/home" isAuthenticated={isAuthenticated} element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>} />

        <Route path="/campaign/edit/:campaignId" isAuthenticated={isAuthenticated} element={
          <PrivateRoute>
            <EditCampaign />
          </PrivateRoute>} />

        <Route path="/transactions/:campaignId" isAuthenticated={isAuthenticated} element={
          <PrivateRoute>
            <Transactions />
          </PrivateRoute>} />

        <Route path="/create-excel-campaign" isAuthenticated={isAuthenticated} element={
          <PrivateRoute>
            <CreateExcelCampaign />
          </PrivateRoute>} />

        <Route path="/excel-campaign/:campaignId" isAuthenticated={isAuthenticated} element={
          <PrivateRoute>
            <ExcelCampaign />
          </PrivateRoute>} />

        <Route path="/batch-transaction" isAuthenticated={isAuthenticated} element={
          <PrivateRoute>
            <BatchTransaction />
          </PrivateRoute>} />


      </Routes>
    </Router>
  );
}

export default App;
