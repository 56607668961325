import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { Request } from "../helpers/https";
import { getLocalStorage } from "../helpers/utils";
import { SimpleToastError, SimpleToastSuccess } from "../helpers/validation/error-handlers";

const CreateExcelCampaign = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(false)


    const getExplorer = (network) => {
        let url;
        switch (network) {
            case 'mainnet':
                url = 'etherscan.io';
                break;
            case 'matic':
                url = 'polygonscan.com';
                break;
            case 'bsc':
                url = 'bscscan.com';
                break;
            default:
                break;
        }
        return url;
    }


    const formatString = (str) => {
        return str.substring(0, 8) + '...' + str.substring(str.length - 6)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const data = new FormData(e.target);
        const values = Object.fromEntries(data.entries());

        let url = `${process.env.REACT_APP_SERVER_URL}excelCampaign/title/create`;
        let token = getLocalStorage('mw:user:session:id');
        let config = {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
        let result = await Request.post(url, values, config)
        if (result.ok) {
            // console.log({ result: result.data.data.data })
            SimpleToastSuccess(result.data.data.data)
            setInterval(() => {
                window.location.reload();
            }, 2000);
        } else {
            // console.log({ result: result.data.response.data })
            SimpleToastError(result.data.response.data.data)
        }
        setLoading(false)
    }


    async function fetchCampaigns() {
        let url = `${process.env.REACT_APP_SERVER_URL}excelCampaign/title/view`;
        let result = await Request.get(url)
        if (result.ok) {
            console.log({xxx:result.data.data.data})
            setCampaigns(result.data.data.data)
        }
    }


    const setCampaignStatus = async (e) => {
        let campaignId = e.target.getAttribute("id")
        let token = getLocalStorage('mw:user:session:id');
        let config = {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
        let url = `${process.env.REACT_APP_SERVER_URL}excelCampaign/status/${campaignId}`;
        let result = await Request.post(url, {}, config)
        // console.log({ result }); return;
        if (result.ok) {
            SimpleToastSuccess('Campaign status updated!')
            setInterval(() => {
                window.location.reload();
            }, 2000);
        } else {
            SimpleToastError(result.data.response.data.data)
        }
    }

    useEffect(async () => {
        await fetchCampaigns()
    }, []);

    return (
        <>
            <div className="App">
                <Header title={'Excel Campaign'} />
                <div className="row ml-auto mr-auto">
                    <form className='m-auto' onSubmit={handleSubmit}>
                        <div className="form-group d-flex mb-4">
                            <div className="col-md-2">
                                <label >Title:</label>
                            </div>
                            <div className="col-md-6">
                                <input type="text" className="form-control" required={true} name="title" placeholder="Enter campaign title..." />

                            </div>
                        </div>



                        {
                            (loading) ?
                                <button type="submit" className="btn btn-primary ms-5">
                                    <div className="spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div> Processing...
                                </button>

                                :
                                <>

                                    <button type="submit" className="btn btn-primary">Create</button>
                                </>
                        }


                    </form>
                </div>

                <div className="container mt-5">
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Created at</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {campaigns?.map((e, index) => (
                            <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{e.title}</td>
                                <td>{e.status}</td>
                                <td>{e.created_at}</td>
                                {
                                    e.status === 'active' &&
                                    <td><button id={e.id} onClick={setCampaignStatus} className="btn btn-danger">Disable</button></td>
                                }
                                {
                                    e.status === 'disabled' &&
                                    <td><button id={e.id} onClick={setCampaignStatus} className="btn btn-success">Enable</button></td>
                                }
                                <td><a href={'excel-campaign/' + e.id} className="btn btn-primary">Leaderboard</a></td>
                            </tr>
                        ))
                        }
                    </tbody>
                </table>
            </div>

            </div>
        </>

    );
}

export default CreateExcelCampaign;