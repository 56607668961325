
import { useEffect, useState } from "react";
import Header from "../components/Header";
import { Request } from "../helpers/https";
import { SimpleToastError, SimpleToastSuccess } from "../helpers/validation/error-handlers";
import { read, utils } from "xlsx";
import { getLocalStorage } from "../helpers/utils";
import { useParams } from "react-router-dom";
import AHeader from "./AHeader";
// import {fileTypeFromFile} from 'file-type';

const ABugCampaign = () => {
    const { campaignId } = useParams();
    const [loading, setLoading] = useState(false)
    const [selectedFile, setSelectedFile] = useState();
    const [sheetData, setSheetData] = useState([]);
    const [mediaCampaigns, setMediaCampaigns] = useState([]);
    const [campaign, setCampaign] = useState([]);

    const displayExcelSheet = async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();

        const excelTable = document.getElementById('excelTable');

        reader.onload = () => {
            const fileData = reader.result;
            const workbook = read(fileData, { type: 'binary' });
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            const sheetData = utils.sheet_to_json(sheet, { header: 1 });

            for (let i = 0; i < sheetData.length; i++) {
                if (typeof sheetData[i][1] !== 'string') {
                    sheetData.splice(i)
                }
            }

            // display the sheet data in a table
            let tableHtml = '';
            for (let row of sheetData) {
                tableHtml += '<tr>';
                for (let cell of row) {
                    tableHtml += '<td>' + cell + '</td>';
                }
                tableHtml += '</tr>';
            }
            excelTable.innerHTML = tableHtml;
            setSheetData(sheetData);
        };

        reader.readAsBinaryString(file);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        sheetData.splice(0, 1)

        let data = []
        sheetData.map((e, i) => { data.push({ "address": e[1], "points": e[2] }) })

        let token = getLocalStorage('mw:user:session:id');
        let config = {
            headers: {
                "Authorization": "Bearer " + token,
            }
        }
        let url = `${process.env.REACT_APP_SERVER_URL}/api/v1/users/reward/bug`;
        let result = await Request.post(url, { data }, config)
        if (result.ok) {
            SimpleToastSuccess(result.data.data.data)
            setInterval(() => {
                window.location.reload();
            }, 2000);
        } else {
            // console.log({ result: result.data })

            SimpleToastError("system error")
        }
        setLoading(false)

    }

    const validateFileExtension = (fileName) => {
        try {
            let justFileName = fileName.split('.')
            let fileExtension = justFileName[1]
            if (fileExtension !== 'xlsx')
                throw new Error("Invalid file format!")

            return { ok: true }
        } catch (err) {
            SimpleToastError(err.message)
            return { ok: false }
        }
    }

    const changeHandler = (event) => {
        let res = validateFileExtension(event.target.files[0].name);
        if (res?.ok) {
            setSelectedFile(event.target.files[0]);
            displayExcelSheet(event)
        }
    };




    return (

        <div className="App">
            <AHeader title={'Bug Campaign'} />
            <div className="row ml-auto mr-auto justify-content-center mb-lg-5">
                <div className="col-md-9">
                    <table id="excelTable" className="table table-striped table-hover"></table>
                </div>
            </div>
            <div className="row ml-auto mr-auto">
                <form className='m-auto' onSubmit={handleSubmit}>
                    <div className="form-group d-flex mb-4">
                        <div className="col-md-2">
                            <label >Select Excel Sheet:</label>
                        </div>
                        <div className="col-md-6">
                            {/* <input type="text" className="form-control" required="true" name="txnHash" placeholder="Enter transaction hash..." /> */}
                            <input type="file" name="file" onChange={changeHandler} />

                        </div>
                    </div>



                    {
                        (loading) ?
                            <button type="submit" className="btn btn-primary ms-5">
                                <div className="spinner-border-sm" role="status">
                                    <span className="sr-only"></span>
                                </div> Processing...
                            </button>

                            :
                            <>

                                <button type="submit" className="btn btn-primary">Upload data for {campaign?.title} bug campaign</button>
                            </>
                    }


                </form>
            </div>
        </div>
    );
}

export default ABugCampaign;