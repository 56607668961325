import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import { Request } from "../helpers/https";
import { getLocalStorage } from "../helpers/utils";
import { SimpleToastError, SimpleToastSuccess } from "../helpers/validation/error-handlers";

const Transactions = () => {
    const { campaignId } = useParams();
    const [transactions, setTransactions] = useState([]);
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(false)


    const getExplorer = (network) => {
        let url;
        switch (network) {
            case 'mainnet':
                url = 'etherscan.io';
                break;
            case 'matic':
                url = 'polygonscan.com';
                break;
            case 'bsc':
                url = 'bscscan.com';
                break;
            default:
                break;
        }
        return url;
    }


    const formatString = (str) => {
        return str.substring(0, 8) + '...' + str.substring(str.length - 6)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        const data = new FormData(e.target);
        const values = Object.fromEntries(data.entries());
        values.campaignId = campaignId;
        // console.log({values});return;

        let url = `${process.env.REACT_APP_SERVER_URL}transactions/`;
        let token = getLocalStorage('mw:user:session:id');
        let config = {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
        let result = await Request.post(url, values, config)
        if (result.ok) {
            // console.log({ result: result.data.data.data })
            SimpleToastSuccess(result.data.data.data)
            setInterval(() => {
                window.location.reload();
            }, 2000);
        } else {
            // console.log({ result: result.data.response.data })
            SimpleToastError(result.data.response.data.data)
        }
        setLoading(false)
    }
    async function fetchTransactions() {
        let url = `${process.env.REACT_APP_SERVER_URL}campaigns/transactions/${campaignId}/1/500`;
        let result = await Request.get(url,)
        // console.log({ 'xxx': result.data.data.data.data })
        if (result.ok) {
            setTransactions(result.data.data.data.data)
        }
    }


    async function fetchCampaigns() {
        let url = `${process.env.REACT_APP_SERVER_URL}campaigns/1/500`;
        let result = await Request.get(url)
        if (result.ok) {
            setCampaigns(result.data.data.data.data)
        }
    }
    useEffect(async () => {
        await fetchTransactions()
        await fetchCampaigns()
    }, []);

    return (
        <>
            <div className="App">
                <Header title={'transactions'} />
                <div className="row ml-auto mr-auto">
                    <form className='m-auto' onSubmit={handleSubmit}>
                        <div className="form-group d-flex mb-4">
                            <div className="col-md-2">
                                <label >Txn Hash:</label>
                            </div>
                            <div className="col-md-6">
                                <input type="text" className="form-control" required={true} name="txnHash" placeholder="Enter transaction hash..." />

                            </div>
                        </div>
                        {/* <div className="form-group d-flex mb-4">
                            <div className="col-md-2">
                                <label> Campaign:</label>
                            </div>
                            <div className="col-md-6">
                                <select className='form-select' name="campaignId" id="type">
                                    <option value="selected">-- Select campaign --</option>
                                    {campaigns?.map((e, index) => (
                                        <option key={index} value={e.id}>{e.name}</option>
                                    ))
                                    }
                                </select>

                            </div>
                        </div> */}
                        <div className="form-group d-flex mb-4">
                            <div className="col-md-2">
                                <label> Network:</label>
                            </div>
                            <div className="col-md-6">
                                <select className='form-select' name="network" id="type">
                                    <option value="selected">-- Select network --</option>
                                    <option value="mainnet">Mainnet</option>
                                    <option value="bsc">BSC</option>
                                    <option value="matic">Matic</option>
                                </select>

                            </div>
                        </div>



                        {
                            (loading) ?
                                <button type="submit" className="btn btn-primary ms-5">
                                    <div className="spinner-border-sm" role="status">
                                        <span className="sr-only"></span>
                                    </div> Processing...
                                </button>

                                :
                                <>

                                    <button type="submit" className="btn btn-primary">Save</button>
                                </>
                        }


                    </form>
                </div>


                <div className="container mt-5">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Txn</th>
                                <th scope="col">Campaign</th>
                                <th scope="col">Txn Type</th>
                                <th scope="col">Network</th>
                                <th scope="col">Amount(USD)</th>
                                <th scope="col">Created at</th>
                            </tr>
                        </thead>
                        <tbody>
                            {transactions?.map((e, index) => (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td><a href={`https://` + getExplorer(e.network) + `/tx/` + e.txn_hash}>{formatString(e.txn_hash)}</a></td>
                                    <td>{e.campaign.name}</td>
                                    <td>{e?.txn_type}</td>
                                    <td>{e.network}</td>
                                    <td>{e.amount_in_usd}</td>
                                    <td>{e.created_at}</td>
                                </tr>
                            ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    );
}

export default Transactions;